
import {richTextConstructor} from "../helpers";

export default {
  name: 'FaqList',

  props: {
    blockOptions: {
      type: Object
    }
  },

  data () {
    return {
      defaultOpened: this.blockOptions.defaultOpened
    }
  },
    methods: {
        contentHTML(content){
            if(content){
                return richTextConstructor(content)
            }else {
                return ''
            }
        }
    }
}
